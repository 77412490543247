import React from 'react'
import ContactForm from '../components/ContactForm'
import Header from '../components/Header'

function ContactUs() {
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide">
      <Header />
      <ContactForm />
      </div>
  )
}

export default ContactUs